import { Breakpoint } from "../hooks/useBreakpoint";

export const getCurrentBreakpoint = (): Breakpoint => {
  const width = window.innerWidth;

  if (width < 375) return "xxs";
  if (width < 576) return "xs";
  if (width < 768) return "sm";
  if (width < 992) return "md";
  if (width < 1200) return "lg";
  if (width < 1400) return "xl";
  if (width < 1800) return "xxl";

  return "xxl";
};
