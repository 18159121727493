import axios from "axios";
import { TGetQuestion, TSendAnswerQuestion } from "./interface/question-answer.interface";

const baseURL = process.env.REACT_APP_API_URL || "https://api.emall.by/api/v1";
const request = axios.create({ baseURL })

export const getQuestion: TGetQuestion = async (id: string) => {
    const res = await request.get(`/questions/${id}`);
    return res.data;
}

export const sendAnswerQuestion: TSendAnswerQuestion = async (id, answer) => {
    const res = await request.put(`/questions/${id}`,  { answer })
    return res.data;
}