import { Fragment } from 'react';
import { MainPage } from './pages/main';
import { FaqPage } from './pages/faq';
import 'ui-kit-euroopt/dist/stylesheets/bundle.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { StateProviderFaq } from './store/faq/context';
import './assets/scss/global.scss';
import Chat from './components/chat';
import { ReviewAnswer } from './pages/reviewAnswer';
import { ReviewThanksPage } from './pages/reviewAnswer/thanksPage';
import { QuestionAnswer } from './pages/questionAnswer';
import { QuestionThanksPage } from './pages/questionAnswer/thanksPage';
import { HelmetProvider, Helmet } from 'react-helmet-async';

function Wrapper() {
	return (
		<HelmetProvider>
			<Helmet>
				<title>Стать селлером на Emall Seller - зарабатывайте с нами</title>
				<meta
					name="description"
					content="Продавайте товары на белорусском маркетплейсе Емолл ⭐️ Расскажем как стать селлером Emall ⭐️ Зарабатывайте вместе с крупнейшим интернет-магазином Емолл"
				/>
			</Helmet>

			<BrowserRouter>
				<Fragment>
					<Routes>
						<Route path="/" element={<MainPage />} />
						<Route
							path="/faq"
							element={
								<StateProviderFaq>
									<FaqPage />
								</StateProviderFaq>
							}
						/>
						<Route path="/review-answer" element={<ReviewAnswer />} />
						<Route path="/review-answer/thanks-page" element={<ReviewThanksPage />} />
						<Route path="/question-answer" element={<QuestionAnswer />} />
						<Route path="/question-answer/thanks-page" element={<QuestionThanksPage />} />
					</Routes>
					<Chat />
				</Fragment>
			</BrowserRouter>
		</HelmetProvider>
	);
}

export default Wrapper;
