import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Check: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 18.2956L12.2897 26.6134C12.9866 27.3126 14.1571 27.1596 14.651 26.3049L28 3.19995"
				stroke="#F4303B"
				strokeWidth="4"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
