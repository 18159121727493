import {FC, useEffect, useState} from "react";
import { Rating } from 'react-simple-star-rating'
import styles from "./index.module.scss";
import {TextAreaForm} from "../../components/form/TextAreaForm";
import {Form, notification} from "antd";
import {useForm} from "antd/lib/form/Form";
import {openModal} from "../../components/modals/anyModal";
import {getReviewAnswer, postReviewAnswer, responseContactPerson} from "../../api/review-answer";
import {IReviewAnswer} from "../../api/review-answer/interface/review-answer.interface";
import {Btn} from "ui-kit-euroopt";
import {useNavigate, useSearchParams} from "react-router-dom";

export const ReviewAnswer: FC = () => {
    const [data, setData] = useState<IReviewAnswer>()
    const [form] = useForm();
    const navigate = useNavigate();
    const [searchParams, _] = useSearchParams();
    const uuid = searchParams.get('uuid');

    useEffect(() => {
        fetchData(uuid as string)
    }, [])

    const onFinish = async (e: any ) => {
         try {
             await postReviewAnswer({answer: e.answer, review_id: data?.review_id});
             navigate(`/review-answer/thanks-page?uuid=${uuid}`)
         } catch (e) {
             notification.error({message: "Что-то пошло не так", style: {borderRadius: "20px"}})
             console.log(e);
         }
    }

    const fetchData = async (id: string) => {
        try {
            const res = await getReviewAnswer(id);
            setData(res);
        } catch (e: any) {
            if (e.response.data.error === "Ответ на отзыв уже оставлен") {
                navigate(`/review-answer/thanks-page?uuid=${uuid}`)
            }
            else {
                navigate(`/`)
            }
        }
    }

    const formatDate = (date: string): string => {
        const dateInitial = new Date(date);
        return `${dateInitial.getDate()}.${dateInitial.getMonth() + 1 < 10 ? '0' + dateInitial.getMonth() : dateInitial.getMonth()}.${dateInitial.getFullYear()}, ${dateInitial.getHours()}:${dateInitial.getMinutes()}`
    }

    const handleCopyText = (value: string) => {
        navigator.clipboard.writeText(value);
        notification.success({message: "Скопировано"});
    }

    const openModalQuestion = async () => {
        try {
            await openModal({
                wrapperClassName: styles.wrapperModal,
                title: "Вы уверены, что хотите запросить контакты клиента?",
                titleSuccess: "Да",
                titleReject: "Нет"
            })
            try {
                try {
                    await responseContactPerson(data?.review_id!)
                    await openModal({
                        wrapperClassName: styles.wrapperModal,
                        className: styles.modal,
                        title: "Ваш запрос принят",
                        iconName: "check-24",
                        titleSuccess: "Ок"
                    })
                } catch (e: any) {
                    if (e.response.data.error === "Контакты уже запрошенны") {
                        notification.error({
                            style: {borderRadius: "20px"},
                            message: e.response.data.error,
                            icon: <div className={styles.warningIconCustom}>!</div>
                        })
                    } else {
                        notification.error({message: "Что-то пошло не так", style: {borderRadius: "20px"}})
                    }
                }
            } catch (e) {
                console.log(e)
                notification.error({message: "Что-то пошло не так", style: {borderRadius: "20px"}})
            }
        }  catch (e) {
            console.log(e)
        }
    }

    return data?.review_comment ? (
        <div className={styles.wrapper}>
            <img className={styles.logo}
                 src="/assets/images/logo.svg?v=1"
                 alt="Emall Seller"/>
            <p className={styles.title}>Поступил новый отзыв на товар</p>
            <div className={styles.wrapperReview}>
                <div className={styles.aboutOffer}>
                    <a style={{display: "contents"}} target="_blank" href={`https://emall.by/product/${data?.offer_id}`}>
                        <img
                            className={styles.offerImg}
                            src={data?.offer_image}
                            alt="offer"
                        />
                    </a>
                    <div>
                        <p className={styles.name}>
                            <a
                                style={{color: "inherit"}}
                                target="_blank" href={`https://emall.by/product/${data?.offer_id}`}
                            >
                                    {data?.offer_name}
                            </a>
                        </p>
                        <p className={styles.offerId}>Арт. <span onClick={() => handleCopyText(data?.offer_id.toString())}>{data?.offer_id}</span></p>
                        <p className={styles.PostalId}>Трек-номер <span onClick={() => handleCopyText(data?.postal_number)}>{data?.postal_number}</span></p>
                    </div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.review}>
                    <p className={styles.nameReviewer}>{data?.review_user_name} <span>{formatDate(data?.review_date as string)}</span></p>
                    {!!data.rating && <div className={styles.rating}><Rating initialValue={data.rating} size={20} /></div>}
                    <p className={styles.textReview}>{data?.review_comment}</p>
                </div>
            </div>
            <p className={styles.textInfo}>Отзыв уже опубликован на сайте. Вы можете отправить ответ на отзыв в течение
                7 дней.</p>
            <p className={styles.textForTextarea}>Ответ на отзыв</p>
            <Form onFinish={onFinish} form={form} className={styles.form}>
                <Form.Item className={styles.textarea} name="answer">
                    <TextAreaForm
                        label=""
                        name="answer"
                        maxLength={1000}
                        showCount={true}
                        mix={styles.textareaForm}
                        rows={6}
                    />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.answer !== nextValues.answer}>
                    {({getFieldValue}) => (
                        <Btn
                            text="Отправить ответ"
                            colour="primary"
                            htmlType="submit"
                            size="medium"
                            mix={styles.btnSubmit}
                            disabled={!getFieldValue("answer")}
                        />
                    )}
                </Form.Item>
            </Form>
            <div className={styles.line}></div>
            <div className={styles.infoFooter}>
                <div>
                    <div className={styles.warningIconCustom}>!</div>
                    <p>Важно</p>
                </div>
                <ul>
                    <li>
                        Мы оставляем за собой право не публиковать ваш ответ, если он будет расцениваться как грубый или
                        не относящийся к существу вопроса.
                    </li>
                    <li>Текст вашего сообщения будет опубликован без редакции с нашей стороны.</li>
                    <li>
                        Ответ на отзыв покупателя можно оставить только в течение 7 дней после получения уведомления о
                        нём.
                    </li>
                    <li>
                        Если отзыв покупателя требует урегулирования, <span
                        onClick={openModalQuestion}>запросите</span> ответным письмом контакты
                        для связи.
                    </li>
                </ul>
            </div>
        </div>
    ) : null
}