import {Header} from "../../components/pages/main/header";
import {Slide} from "../../components/pages/main/slide";
import {Container} from "../../components/container";
import {About} from "../../components/pages/main/about";
import {Mission} from "../../components/pages/main/mission";
import {HowWork} from "../../components/pages/main/how-work";
import {Settlements} from "../../components/pages/main/settlements";
import {Selling} from "../../components/pages/main/selling";
import {What} from "../../components/what";
import {Banner} from "../../components/pages/main/banner";
import {Footer} from "../../components/pages/main/footer";
import React from "react";

import style from './index.module.scss';

export const MainPage = () => (
    <div className={style.wrapper}>
        <Header />
        <Slide />
        <Container>
            <About />
            <Mission />
            <HowWork />
            <Settlements />
            <Selling />
            <What />
            <Banner />
            {/* <Faq /> */}
        </Container>
        <Footer />
    </div>
)