import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FC, useEffect, useRef } from 'react';
import { Icon } from 'ui-kit-euroopt';
import cn from 'classnames';
import styles from './index.module.scss';
import { useStateFaq } from '../../../../store/faq/context';
import { Pagination } from 'antd';
import { useIsMobile } from '../../../../hooks/useIsMobile';
import { plural } from '../../../../helpers/plural';

interface ISearch {
	isModal?: boolean;
}

export const Search: FC<ISearch> = ({ isModal = false }) => {
	const {
		state: {
			search: { isOnPageSearch, value, error, items, page },
		},
		dispatch,
	} = useStateFaq();

	const isMobile = useIsMobile();
	const ref = useRef<HTMLInputElement>(null);

	const setSearch = (value: string) => {
		dispatch({ type: 'SET_SEARCH', payload: value });
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [page]);

	useEffect(() => {
		// if (isMobile) dispatch({ type: 'SET_IS_ON_PAGE_SEARCH', payload: true });
		if (isOnPageSearch) ref.current?.focus();
	}, [isMobile, isOnPageSearch]);

	return (
		<div className={cn(styles.wrap, { [styles.wrap__modal]: isModal })} onClick={(e) => e.stopPropagation()}>
			<div className={styles.header}>
				<label className={cn(styles.search, { [styles.search__inner]: error || items.length })}>
					<Icon mix={styles.search__icon} name="search-24" size={24} />
					<input
						className={styles.search__input}
						ref={ref}
						type="text"
						name="search"
						id="search"
						value={value}
						onChange={(evt) => setSearch(evt.target.value)}
						onKeyUp={(evt) => {
							if (evt.key === 'Enter') {
								if (!isMobile) {
									dispatch({ type: 'SET_IS_ON_PAGE_SEARCH', payload: true });
								}
							}
						}}
						placeholder="Поиск"
						autoFocus
					/>
					{!!value.length && (
						<button className={styles.search__clean} type="button" onClick={() => setSearch('')}>
							<Icon mix={styles.search__icon} name="close-20" size={20} />
						</button>
					)}
				</label>
				{isMobile && (
					<button onClick={() => dispatch({ type: 'CANCEL_SEARCH' })} className={styles.search__cancel} type="button">
						Отмена
					</button>
				)}
			</div>
			{error || !items.length ? (
				<OverlayScrollbarsComponent
					className={cn(styles.result, { [styles.result__nothing]: !error && !items.length })}
					options={{ scrollbars: { autoHide: 'move' } }}
				>
					<div className={styles.content}>
						{(error || !items.length) &&
							(error ? (
								<h3 className={cn(styles.result__title, styles.error__title)}>{error}</h3>
							) : (
								<div className={styles.empty}>
									<h3 className={styles.empty__title}>Ничего не найдено</h3>
									{!error && (
										<p className={styles.empty__text}>
											<span>Проверьте правильность написания или поищите статьи на</span>{' '}
											<a href="/faq" className={styles.empty__link}>
												Главной
											</a>
										</p>
									)}
								</div>
							))}
					</div>
				</OverlayScrollbarsComponent>
			) : (
				<OverlayScrollbarsComponent className={styles.result} options={{ scrollbars: { autoHide: 'move' } }}>
					<div className={styles.content}>
						{!isMobile ? (
							<h3
								className={cn(styles.result__title, {
									[styles.result__error]: error,
									[styles.result__nothing]: !error && !items.length,
								})}
							>
								{error || (items.length > 0 ? (value ? 'Статьи' : 'Популярные статьи') : '')}
							</h3>
						) : (
							<h3 className={styles.result__title}>
								{items.length
									? value
										? `Найдено ${items.length} ${plural(items.length, 'статья', 'статьи', 'статей')}`
										: 'Популярные статьи'
									: ''}
							</h3>
						)}

						{(error || !items.length) &&
							(error ? (
								<h3 className={cn(styles.result__title, styles.error__title)}>{error}</h3>
							) : (
								<div className={styles.empty}>
									<h3 className={styles.empty__title}>Ничего не найдено</h3>
									{!error && (
										<p className={styles.empty__text}>
											<span>Проверьте правильность написания или поищите статьи на</span>{' '}
											<a href="/faq" className={styles.empty__link}>
												Главной
											</a>
										</p>
									)}
								</div>
							))}
						<ul className={styles.result__list}>
							{items
								.slice(0, value ? undefined : 6)
								.filter((_, i, arr) => {
									if (isModal || isMobile) {
										return true;
									}

									const start = 0;
									const end = 10;
									return i >= start + 10 * (page - 1) && i < end * page;
								})
								.map(({ label, title, subTitle, key, subKey }, i) => {
									return (
										<li
											key={i}
											className={cn(styles.result__item, styles.item, { [styles.item__modal]: isModal })}
											onClick={() => dispatch({ type: 'SET_CATEGORY', payload: { key, subKey } })}
										>
											<h4 className={styles.item__title}>{title}</h4>
											<p className={styles.item__description}>{subTitle}</p>
											{!isModal && !isMobile && (
												<button
													onClick={(evt) => {
														evt.stopPropagation();
														dispatch({ type: 'SET_CATEGORY', payload: { key, subKey: null } });
													}}
													className={styles.item__button}
													type="button"
												>
													{label}
												</button>
											)}
										</li>
									);
								})}
						</ul>
						{!error && !isModal && items.length > 10 && value && !isMobile && (
							<Pagination
								size="small"
								className={styles.pagination}
								total={items.length}
								showLessItems
								showSizeChanger={false}
								showQuickJumper={false}
								current={page}
								prevIcon={<Icon name="chevron-16" mix={styles.pagination__prev} size={16} />}
								nextIcon={<Icon name="chevron-16" mix={styles.pagination__next} size={16} />}
								onChange={(page) => dispatch({ type: 'SET_SEARCH_PAGE', payload: page })}
							/>
						)}
					</div>
				</OverlayScrollbarsComponent>
			)}
		</div>
	);
};
