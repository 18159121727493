import { FC } from 'react';
import { Search } from '../search';
import styles from './index.module.scss';
import { useStateFaq } from '../../../../store/faq/context';

export const Modal: FC = () => {
	const {
		state: {
			search: { isOpenSearch },
		},
		dispatch,
	} = useStateFaq();

	if (!isOpenSearch) return null;

	return (
		<>
			<div className={styles.overlay} onClick={() => dispatch({ type: 'SET_IS_OPEN_SEARCH', payload: false })}>
				<Search isModal />
			</div>
		</>
	);
};
