/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { message, Modal, Upload, UploadProps } from "antd";
import type { RcFile } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import clsx from "clsx";
import update from "immutability-helper";
import { HTML5toTouch } from "rdndmb-html5-to-touch";
import React, { FC, useCallback, useEffect, useState } from "react";
import { DndProvider, useDrag, useDragLayer, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { MultiBackend } from "react-dnd-multi-backend";
import { Icon } from "ui-kit-euroopt";
import { TIcon } from "ui-kit-euroopt/src/components/icon/icon.interface";

import cls from "./index.module.scss";

interface IUploadForm extends UploadProps {
  label?: string;
  disabled?: boolean;
  value?: string[] | RcFile[] | Blob[];
  mix?: string;
  multiple?: boolean;
  text?: string;
  isNoteDelete?: boolean;
  accept?: string;
  listType?: "text" | "picture" | "picture-card";
  removeText?: boolean;
  iconPrefix?: TIcon;
  names?: string[];
  submit?: () => void;
  onChange?: (e: any) => void;
  setFields?: (fields: any[]) => void;
  getFieldError?: (name: string) => string[];
}
interface IurlToObject {
  name: string;
  uid: string;
  url: string;
}

const urlToObject = (image: string): IurlToObject => {
  const array = image.split("/");
  const name = array[array.length - 1];
  return {
    uid: name,
    name: name,
    url: image,
  };
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const CustomDragLayer: FC<any> = () => {
  const { itemType, isDragging, item, clientOffset } = useDragLayer(
    (monitor) => ({
      item: monitor.getItem(),
      itemType: monitor.getItemType(),
      clientOffset: monitor.getClientOffset(),
      isDragging: monitor.isDragging(),
    })
  );
  const renderItem = (): null | JSX.Element => {
    switch (itemType) {
      case "ITEM":
        return (
          <div
            className={clsx(cls.wrapperImg__base, cls.wrapperImg__blocks__img)}
            style={{ background: "white" }}
          >
            <img src={item.item.url} alt="img" />
          </div>
        );
      default:
        return null;
    }
  };

  if (!isDragging) {
    return null;
  }
  return (
    <div
      style={{
        position: "fixed",
        left: clientOffset?.x ?? 0,
        top: clientOffset?.y ?? 0,
        zIndex: 1,
        pointerEvents: "none",
      }}
    >
      {renderItem()}
    </div>
  );
};

const DraggableItem: FC<any> = ({
  item,
  index,
  isError,
  moveItem,
  children,
  first,
  change,
  isNoteDelete,
  getFieldError,
}) => {
  const [{ isActiveDrag }, ref, preview] = useDrag({
    type: "ITEM",
    item: { index, item },
    collect: (monitor) => {
      return {
        isActiveDrag: monitor.isDragging(),
      };
    },
  });

  const [{ isActive }, drop] = useDrop({
    accept: "ITEM",
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
    }),
    drop: (draggedItem: { index: number }) => {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  return (
    <div
      ref={(node) => ref(drop(node))}
      className={clsx(
        cls.wrapperImg__base,
        isActive && cls.wrapperImg__base__active,
        first ? cls.wrapperImg__main : cls.wrapperImg__blocks__img,
        getFieldError("files").length && isError && cls.error
      )}
      style={isActiveDrag ? { opacity: 0.2 } : undefined}
    >
      {!isNoteDelete && (
        <span onClick={change} className={cls.wrapperImg__base__icon}>
          <Icon name="cross2-16" />
        </span>
      )}
      {children}
    </div>
  );
};

export const UploadForm: FC<IUploadForm> = ({
  value,
  multiple,
  isNoteDelete,
  text = "До 5 фото в формате JPEG/PDF, максимальный размер одного файла - 5МБ.",
  disabled,
  accept = `.jpeg, .png`,
  removeText = false,
  iconPrefix = "",
  listType,
  names,
  onChange,
  setFields,
  getFieldError,
  ...propsUpload
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const handleCancel = (): void => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile): Promise<void> => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  const show =
    (!multiple && !(typeof value === "string" || !!value?.length)) || multiple;

  const props: UploadProps = {
    name: "file",
    multiple: multiple,
    listType: listType ?? "picture-card",
    accept: accept,
    onPreview: handlePreview,
    fileList: multiple
      ? null
      : typeof value === "string"
      ? [urlToObject(value)]
      : (value as any),
    className: clsx(cls.upload, !show && cls.upload__hide),
    beforeUpload: (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function () {
        const newValue = [...(value || [])];
        newValue?.push({
          ...file,
          name: file.name,
          url: reader.result,
          file: file,
        } as any);
        onChange && onChange(newValue);
      };
      return false;
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "removed") {
        onChange && onChange(info.fileList);
      } else if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
    ...propsUpload,
  };

  const fieldErrors =
    names?.length && getFieldError && multiple
      ? names.map((el) => {
          const fieldError = getFieldError(el);
          return typeof fieldError === "string" ? fieldError : fieldError[0];
        })
      : [];

  const setFieldsChange = (index: number): void => {
    if (fieldErrors.length && setFields && multiple && names) {
      try {
        setFields([
          {
            name: names[index],
            errors: [],
          },
        ]);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (!Array.isArray(value)) return;
      const dragCard = value[dragIndex];
      const result = update(value, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard],
        ],
      });
      if (JSON.stringify(value) !== JSON.stringify(result)) {
        setFieldsChange(dragIndex);
        onChange && onChange(result);
      }
    },
    [value]
  );

  const deleteCard = (idx: number): void => {
    if (!value) return;
    const newArr = [...value];
    newArr.splice(idx, 1);
    setFieldsChange(idx);
    onChange && onChange(newArr);
  };

  return (
    <>
      {multiple && (
        <div>
          <DndProvider backend={MultiBackend} options={HTML5toTouch}>
            {Array.isArray(value) && !!value.length && (
              <div
                className={clsx(
                  cls.wrapperImg,
                  isNoteDelete && cls.wrapperImg__isNoteDelete
                )}
              >
                <CustomDragLayer />
                <div className={cls.wrapperImg__blocks}>
                  {value.map((el: any, idx) => {
                    return (
                      <DraggableItem
                        key={idx}
                        item={el}
                        index={idx}
                        moveItem={moveCard}
                        isNoteDelete={isNoteDelete}
                        change={() => deleteCard(idx)}
                        isError={!!fieldErrors[idx]}
                        getFieldError={getFieldError}
                      >
                        <img src={el.url} alt="img" />
                      </DraggableItem>
                    );
                  })}
                </div>
              </div>
            )}
          </DndProvider>
        </div>
      )}
      {!isNoteDelete && (
        <div className={cls.weapperUpload}>
          <Upload.Dragger
            disabled={disabled}
            showUploadList={{ showPreviewIcon: false }}
            {...props}
          >
            {!removeText && (
              <p className="ant-upload-text">
                Выберите файл или перетащите сюда
              </p>
            )}
            {text && (
              <p className={cls["ant-upload-hint"]}>
                {iconPrefix && <Icon name={iconPrefix} />} <span>{text}</span>
              </p>
            )}
          </Upload.Dragger>
        </div>
      )}
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </>
  );
};
