import {FC, useState} from 'react';
import styles from './index.module.scss';
import {Button} from '../../../button';
import {goTo} from '../../../../helpers/goTo';
import classNames from 'classnames';
import {Burger} from '../../../icons/burger';
import {config} from "../../../../config";
import {Link} from "react-router-dom";

export const Header: FC = () => {
	const [isActionsOpened, setIsActionsOpened] = useState(false);

	return (
		<>
			<div className={styles.wrapperHeader}>
				<header className={styles.header}>
					<div className={styles.header_content}>
						<img className={styles.logo} width={130} height={24}
							 src="/assets/images/logo.svg?v=1"
							 alt="Emall Seller"/>
						<div
							className={classNames(styles.actions, {
								[styles.actions_opened]: isActionsOpened,
							})}
						>
							<div className={styles.buttons}>
								<Button onClick={goTo.registration} text="Стать продавцом" type="primary"
										size="medium"/>
								<Button onClick={goTo.login} text="Войти" type="secondary" size="medium"/>
								<a target="_blank" href="https://t.me/emallby" className={styles.telegram_link}
								   rel="noreferrer">
									<img width={36} height={36} src="/assets/images/telegram.svg" alt="Телеграм"/>
								</a>
							</div>
						</div>
						<button className={styles.menu} onClick={() => setIsActionsOpened(prev => !prev)}>
							<Burger
								className={classNames(styles.burger, {
									[styles.burger_cross]: isActionsOpened,
								})}
							/>
						</button>
					</div>
				</header>
				<div className={styles.wrapperNavigation}>
					<nav className={styles.navigation}>
						<a target="_blank" href={`${config.api.url}/emall_agreement.pdf`} rel="noreferrer noopener">Договор
							присоединения</a>
						<a target="_blank" href={`${config.api.url}/postal_agreement.pdf`} rel="noreferrer noopener">Договор
							оказания услуг почтовой связи</a>
						<a target="_blank" href="https://emall.by/information/help-seller">База знаний</a>
						<a target="_blank" href={`${config.api.url}/tarifs.pdf`} rel="noreferrer">Тарифы</a>
						<a target="_blank" rel="noreferrer noopener" href="/promotions_tariffs.pdf">Акционные тарифы</a>
						<Link className={styles.hideLink} to="/faq">FAQ</Link>
						<Link className={styles.hideLink} to="/review-answer">Оставить отзыв на товар</Link>
						<Link className={styles.hideLink} to="/review-answer/thanks-page">Спасибо за ответ</Link>
						<Link className={styles.hideLink} to="/question-answer">Задать вопрос по товару</Link>
						<Link className={styles.hideLink} to="/question-answer/thanks-page">Спасибо за ответ</Link>
					</nav>
				</div>
			</div>
			<div onClick={() => setIsActionsOpened(false)}
				 className={classNames(styles.overlay, {[styles.overlay_active]: isActionsOpened})}/>
		</>
	);

};
