import { FC, useState, Fragment } from 'react';
import { Title } from '../../../title';
import styles from './index.module.scss';
import { Button } from '../../../button';
import { goTo } from '../../../../helpers/goTo';
import classNames from 'classnames';
import { Card } from '../../../icons/card';
import { Place } from '../../../icons/place';
import { Chevron } from '../../../icons/chevron';
import Tooltip from '../../../tooltip';
import { Check } from '../../../icons/check';
import { Question } from '../../../icons/question';
import { Calc } from '../../../calc';
import { Tariffs } from '../../../tariffs';

export const Settlements: FC = () => {
	const [isAllTariffsModalOpened, setIsAllTariffsModalOpened] = useState(false);

	return (
		<Fragment>
			{isAllTariffsModalOpened && <Tariffs onClose={() => setIsAllTariffsModalOpened(false)} />}

			<section className={styles.section}>
				<Title title="Условия и взаиморасчёты" />
				<div className={classNames(styles.block, styles.block_intro)}>
					<h5 className={classNames(styles.title, styles.title_smile)}>
						Всего <span className={styles.title_red}>4%</span> от суммы продаж
					</h5>
					<span className={styles.text}>
					Фиксированный % вознаграждения вне зависимости от категории и стоимости товара
					</span>
				</div>
				<div className={styles.block}>
					<div className={styles.split}>
						<div className={styles.block_intro}>
							<h5 className={styles.title}>От 1,88 р.</h5>
							<span className={styles.text}>За доставку и выдачу товара</span>
						</div>
						<div>
							<Button
								text="Все тарифы"
								type="secondary"
								size="medium"
								onClick={() => setIsAllTariffsModalOpened(true)}
								icon={<Chevron className={styles.chevron} />}
							/>
						</div>
					</div>
					<div className={styles.separator} />
					<div className={styles.checkboxes}>
						<span className={styles.checkboxes__title}>
							При всех тарифах НЕ требуется дополнительная оплата следующих услуг (всё включено в тариф):
						</span>
						<div className={styles.checkboxes__row}>
							<Check className={styles.checkboxes__check} />
							<span className={styles.checkboxes__name}>Объявленная ценность</span>
							<Tooltip
								id="id_1"
								message="Это, по сути, страховая стоимость. Если товар утерян/уничтожен, то выплачивается компенсация в размере объявленной ценности (обычно она равна цене товара)"
							>
								<Question />
							</Tooltip>
						</div>
						<div className={styles.checkboxes__row}>
							<Check className={styles.checkboxes__check} />
							<span className={styles.checkboxes__name}>Наложенный платёж</span>
							<Tooltip
								id="id_2"
								message="Опция позволяет покупателю оплатить заказ в момент получения"
							>
								<Question />
							</Tooltip>
						</div>
						<div className={styles.checkboxes__row}>
							<Check className={styles.checkboxes__check} />
							<span className={styles.checkboxes__name}>Проверка комплектности</span>
							<Tooltip
								id="id_3"
								message="Опция позволяет получателю до момента оплаты наложенного платежа вскрыть отправление и осмотреть товар на соответствие заказу и отсутствие повреждений"
							>
								<Question />
							</Tooltip>
						</div>
						<div className={styles.checkboxes__row}>
							<Check className={styles.checkboxes__check} />
							<span className={styles.checkboxes__name}>Перемаркировка отправления в случае необходимости</span>
							<Tooltip id="id_4" message="Услуга по замене адресного ярлыка необходима, когда сканером не читается штрих код либо сам ярлык поврежден">
								<Question />
							</Tooltip>
						</div>
					</div>
				</div>
				<div id="calc">
					<div className={styles.block}>
						<div className={styles.block_intro}>
							<h5 className={styles.title}>Вы получаете сумму на счёт в течение 1-3 дней</h5>
							<span className={styles.text}>После получения заказа покупателем</span>
						</div>
						<div className={styles.separator} />
						<div className={styles.bonuses}>
							<div className={styles.bonus}>
								<Card />
								<span className={styles.bonus__title}>При оплате онлайн</span>
								<span className={styles.bonus__description}>Получаете сумму за вычетом вознаграждения</span>
							</div>
							<div className={styles.bonus}>
								<Place />
								<span className={styles.bonus__title}>При оплате в момент получения</span>
								<span className={styles.bonus__description}>Получаете 100% стоимости товара</span>
							</div>
						</div>
					</div>
					<span className={styles.info}>
						Стоимость почтовых услуг и сумма вознаграждения (если не была удержана в момент онлайн-оплаты заказа)
						выставляются актами раз в месяц
					</span>
				</div>
				<div className={styles.calc}>
					<h5 className={styles.title}>Давайте посчитаем</h5>
					<div className={styles.block}>
						<Calc />
					</div>
				</div>
				<Button text="Стать продавцом" type="primary" size="large" onClick={goTo.registration} />
			</section>
		</Fragment>
	);
};
