/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx';
import { FC, Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import { Icon } from 'ui-kit-euroopt';

import cls from './index.module.scss';
import { useLocation } from 'react-router-dom';
import { Touchable } from '../touchable';

let chat: IChatBotApi | null = null;

export interface IChatBotApi {
	getWidgetState: () => boolean;
	openWidget: () => void;
	closeWidget: () => void;
}

declare global {
	interface Window {
		getWebChatCraftTalkExternalControl: (data: IChatBotApi) => unknown;
	}
}

const Chat: FC = () => {
	const router = useLocation();
	const wrapper = useRef<HTMLDivElement | null>(null);

	// const [loadChat, setLoadChat] = useState(false);
	const [webChatIsReady, setWebChatIsReady] = useState(false);
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		window.getWebChatCraftTalkExternalControl = (api) => {
			setWebChatIsReady(true);
			chat = api;
		};
	}, []);

	useEffect(() => {
		if (expanded) {
			// setLoadChat(true);
		}
	}, [expanded]);

	const openOnlineChat = (event: SyntheticEvent): void => {
		event.preventDefault();

		if (webChatIsReady) {
			setExpanded(false);

			if (chat) {
				const opened: boolean = chat.getWidgetState();
				opened ? chat.closeWidget() : chat.openWidget();
			}
		}
	};

	const withCart = router.pathname !== '/cart';

	return (
		<Fragment>
			<div
				ref={wrapper}
				className={clsx(cls.chat, {
					[cls.chat_with_cart]: withCart,
				})}
			>
				<Touchable aria-label="Поддержка" onClick={() => setExpanded(!expanded)} className={cls.button}>
					<Icon size={24} name={expanded ? 'close' : 'message'} mix={cls.button__icon} />
				</Touchable>
				{/* <a
					onClick={() => setExpanded(false)}
					className={clsx(cls.item, cls.item_1, cls.item_viber, {
						[cls.item_visible]: expanded,
					})}
					href="viber://pa?chatURI=emallbot"
					target="_blank"
					rel="noreferrer"
					title="Viber"
				>
					<img style={{ width: 56, height: 56 }} src="/assets/icons/viber.svg?ver=1" alt="Viber" />
				</a> */}
				<a
					onClick={() => setExpanded(false)}
					className={clsx(cls.item, cls.item_2, cls.item_telegram, {
						[cls.item_visible]: expanded,
					})}
					href="https://t.me/emall_seller_bot"
					target="_blank"
					rel="noreferrer"
					title="Telegram"
				>
					<img style={{ width: 56, height: 56 }} src="/assets/icons/telegram.svg?ver=1" alt="telegram" />
				</a>
				<a
					onClick={openOnlineChat}
					className={clsx(cls.item, cls.item_chat, cls.item_3, {
						[cls.item_visible]: expanded,
					})}
					href="#"
					target="_blank"
					rel="noreferrer"
					title="Онлайн чат"
				>
					<Icon spin={!webChatIsReady} size={27} mix={cls.item__icon} name={webChatIsReady ? 'message' : 'spinner'} />
				</a>
			</div>
		</Fragment>
	);
};

export default Chat;
