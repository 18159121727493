import styles from './index.module.scss';
import {Icon} from 'ui-kit-euroopt';
import {Link} from 'react-router-dom';
import {useIsMobile} from '../../../../hooks/useIsMobile';
import {useStateFaq} from '../../../../store/faq/context';
import {openModalFeedback} from '../../../modals/feedbackModal';
import cn from 'classnames';
import {useEffect} from 'react';

export const Header = () => {
	const isMobile = useIsMobile();
	const { dispatch, state } = useStateFaq();

	useEffect(() => {
		document.body.style.overflowY = state.isOpenMobMenu ? 'hidden' : 'auto';
	}, [state.isOpenMobMenu]);

	return (
		<header
			className={cn(styles.header, {
				[styles.header_hidden]: isMobile && state.search.isOnPageSearch,
			})}
		>
			<div className={styles.wrap}>
				<Link to="/">
					<img className={styles.logo} width={155} height={24} src="/assets/images/logo.svg?v=1" alt="Emall Seller" />
				</Link>
				<div className={styles.btns}>
					<button
						className={styles.btn}
						onClick={() => {
							dispatch({ type: 'SET_IS_OPEN_SEARCH', payload: true });
							dispatch({ type: 'SET_IS_OPEN_MOBMENU', payload: false });
						}}
						type="button"
					>
						<Icon name="search-24" size={24} />
					</button>
					<button
						className={styles.btn}
						onClick={() => {
							openModalFeedback(false);
							dispatch({ type: 'SET_IS_OPEN_MOBMENU', payload: false });
						}}
						type="button"
					>
						<Icon name="chat-24" size={24} />
					</button>
					<button
						className={cn(styles.btn, styles.btn__menu)}
						onClick={() => dispatch({ type: 'SET_IS_OPEN_MOBMENU', payload: !state.isOpenMobMenu })}
						type="button"
					>
						{!state.isOpenMobMenu && <Icon name="menu-24" size={24} />}
						{state.isOpenMobMenu && <Icon name="close-24" size={24} />}
					</button>
				</div>
			</div>
		</header>
	);
};
