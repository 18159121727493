import styles from './index.module.scss';
import dataFAQ from '../../components/pages/faq/mockData';
import Sidebar from '../../components/pages/faq/sidebar';
import HelpInfo from '../../components/pages/faq/helpInfo';
import clsx from 'clsx';
import { Modal } from '../../components/pages/faq/modal';
import { Header } from '../../components/pages/faq/header';
import { useStateFaq } from '../../store/faq/context';
import { Search } from '../../components/pages/faq/search';
import { useEffect } from 'react';
import { openModalFeedback } from '../../components/modals/feedbackModal';
import { useIsMobile } from '../../hooks/useIsMobile';
import { Icon } from 'ui-kit-euroopt';
import { Helmet } from 'react-helmet-async';

export const FaqPage = () => {
	const {
		state: {
			search: { isOnPageSearch },
			menu: { activeKey, activeSubKey },
		},
		dispatch,
	} = useStateFaq();
	const isMobile = useIsMobile('sm');

	const setActiveSubKey = (key: number | null) => {
		dispatch({ type: 'SET_ACTIVE_SUB_KEY', payload: key });
	};

	useEffect(() => {
		if (window.location.hash) {
			const key = window.location.hash.replace('#', '');
			dispatch({ type: 'SET_CATEGORY', payload: { key, subKey: null } });
		}
	}, []);

	useEffect(() => {
		if (window) {
			window.scrollTo({ top: 0 });
		}
	}, [activeKey, activeSubKey]);

	return (
		<>
			<Helmet>
				<title>FAQ База знаний для селлеров на Emall Seller</title>
				<meta
					name="description"
					content="Кто может стать продавцом на маркетплейсе Емолл ⭐️ Расскажем как начать работу и добавить товары в продажу ⭐️ Работа с товарами и заказами, документооборот"
				/>
			</Helmet>

			<Header />
			<div className={clsx(styles.wrapper, styles.content)}>
				<Sidebar />
				<div className={clsx(styles.wrapperContentFaq, { [styles.wrapperContentFaq__search]: isOnPageSearch })}>
					{isOnPageSearch ? (
						<Search />
					) : activeSubKey || activeSubKey === 0 ? (
						<div className={styles.infoPage}>
							<div className={styles.breadCrumbs}>
								{!isMobile ? (
									<>
										<span onClick={() => setActiveSubKey(null)}>{dataFAQ[activeKey].label}</span>
										<div>
											<Icon name="chevron-12" size={12} mix={styles.breadCrumbs__chevron} />
										</div>
										<span>
											{
												//@ts-ignore
												dataFAQ[activeKey].items[activeSubKey].title
											}
										</span>
									</>
								) : (
									<>
										<div onClick={() => setActiveSubKey(null)} className={styles.breadCrumbs__mobile}>
											<Icon name="chevron-12" size={12} mix={styles.breadCrumbs__chevron} />
											<span>{dataFAQ[activeKey].label}</span>
										</div>
									</>
								)}
							</div>
							{//@ts-ignore
							dataFAQ[activeKey].items[activeSubKey]?.content(openModalFeedback)}
						</div>
					) : activeKey === 0 ? (
						dataFAQ.map((item) => <HelpInfo key={item.label} item={item} />)
					) : (
						<HelpInfo item={dataFAQ[activeKey]} />
					)}
				</div>
			</div>
			<Modal />
		</>
	);
};
