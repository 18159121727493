import React from 'react';
import './index.scss';
import Wrapper from './app';
import { hydrateRoot, createRoot } from "react-dom/client";

const rootElement = document.getElementById("root");

if (rootElement) {
    if (rootElement.hasChildNodes()) {
        hydrateRoot(rootElement, <Wrapper />);
    } else {
        createRoot(rootElement).render(<Wrapper />);
    }
}



