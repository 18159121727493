import { ColumnsType } from 'antd/lib/table';

export const dataSourceUploadGoods = [
	{
		key: '1',
		status: 'На проверке',
		value: 'В процессе модерации со стороны emall.by.',
		action:
			'Ждать смену статуса на «Готов к продаже» или «Требуется исправление». Модерация занимает около 1 рабочего дня.',
	},
	{
		key: '2',
		status: 'Готов к продаже',
		value: 'Товар прошел модерацию, можно размещать его в продажу.',
		action:
			'Размещать в продажу. Для этого нужно указать остатки и цены, а затем в контекстном меню (три точки) нажать “Разместить в продажу” .',
	},
	{
		key: '3',
		status: 'Требуется исправление',
		value: 'Требуется внести исправления после модерации (не выполнены требования к оформлению).',
		action:
			'В контекстном меню (три точки) выбрать функцию "Редактировать". Справа будет виден комментарий с рекомендациями. Необходимо внести исправления и повторно отправить товар на проверку, нажав кнопку "Сохранить".',
	},
	{
		key: '4',
		status: 'В продаже',
		value: 'Товар отображается в каталоге и доступен к заказу.',
		action: '🙂',
	},
	{
		key: '5',
		status: 'Не продается',
		value: 'Вы сами сняли товар с продажи.',
		action: 'Можете оставить в этом статусе либо разместить в продажу.',
	},
	{
		key: '6',
		status: 'Заблокировано',
		value:
			'Карточка заблокирована со стороны emall.by (на основании проблем, которые препятствуют продаже товара, например, возвраты, жалобы и прочее).',
		action: 'Устранить проблемы, которые препятствуют продаже товара.',
	},
];

export const columnsUploadGoods: ColumnsType<any> = [
	{
		title: 'Статус',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Значение',
		dataIndex: 'value',
		key: 'value',
	},
	{
		title: 'Что делать',
		dataIndex: 'action',
		key: 'action',
	},
];

export const dataSourceWorksImages = [
	{
		key: '1',
		param: 'Формат',
		requirements: 'JPEG, JPG, PNG',
	},
	{
		key: '2',
		param: 'Разрешение',
		requirements: 'От 200 x 200 до 4320 x 7680 пикселей. Оптимальное - 900*1200.',
	},
	{
		key: '3',
		param: 'Соотношение сторон',
		requirements: 'Рекомендовано 3:4',
	},
	{
		key: '4',
		param: 'Размер',
		requirements: 'Не больше 10 МБ',
	},
	{
		key: '5',
		param: 'Количество изображений в одной карточке',
		requirements: '10. Одно главное и остальные - дополнительные',
	},
];

export const columnsWorksImages: ColumnsType<any> = [
	{
		title: 'Параметры',
		dataIndex: 'param',
		key: 'param',
	},
	{
		title: 'Требования',
		dataIndex: 'requirements',
		key: 'requirements',
	},
];
