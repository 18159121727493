import { Empty, Select, SelectProps } from "antd";
import { NamePath } from "antd/lib/form/interface";
import clsx from "clsx";
import { useIsMobile } from "../../../hooks/useIsMobile";
import { BaseSelectRef } from "rc-select";
import {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  ReactNode,
  useRef,
  useState,
} from "react";
import * as React from "react";
import { Icon } from "ui-kit-euroopt";

import cls from "./index.module.scss";

type TValue = ChangeEvent<HTMLInputElement> | string | number[] | string[];

type TOptions = {
  value?: string | number | null | undefined;
  label: ReactNode;
}[];

type TOnChange = ChangeEventHandler<HTMLInputElement>;
export interface ISelectOpenModal {
  value: TValue;
  options?: TOptions;
  onChange?: TOnChange;
}

interface ISelectForm extends SelectProps {
  label?: string;
  disabled?: boolean;
  value?: TValue;
  dropdownRender?: (
    menu: React.ReactElement,
    onClick?: TOnChange,
    setOpen?: (e: boolean) => void
  ) => React.ReactElement;
  name: NamePath;
  mix?: string;
  mixSelect?: string;
  mixPopup?: string;
  openOptions?: boolean;
  isError?: boolean;
  onChange?: TOnChange;
  getFieldError?: (name: NamePath) => string[];
  setOpenOptions?: (b: boolean) => void;
  onOpenModal?: (
    value: ISelectOpenModal["value"],
    options?: ISelectOpenModal["options"],
    onChange?: ISelectOpenModal["onChange"]
  ) => void;
}

export const SelectForm: FC<ISelectForm> = ({
  value,
  label,
  disabled,
  name,
  mix,
  mixSelect,
  mixPopup,
  options,
  openOptions,
  isError,
  onChange,
  dropdownRender,
  getFieldError,
  setOpenOptions,
  onOpenModal,
  ...props
}) => {
  const isMobile = useIsMobile("md");
  const message =
    isError ||
    (getFieldError
      ? typeof getFieldError(name) === "string"
        ? getFieldError(name)
        : getFieldError(name)[0]
      : "");

  const [open, setOpen] = useState(false);

  const refSelect = useRef<HTMLElement>();

  const openV =
    typeof openOptions !== "undefined" && typeof setOpenOptions !== "undefined"
      ? openOptions
      : open;

  const setOpenV =
    typeof openOptions !== "undefined" && typeof setOpenOptions !== "undefined"
      ? setOpenOptions
      : setOpen;

  return (
    <div className={clsx(cls.wrapper, mix)}>
      <Select
        value={value as ChangeEvent<HTMLInputElement>}
        onChange={onChange}
        onSelect={() => {
          refSelect.current?.blur();
        }}
        size="large"
        ref={refSelect as React.Ref<BaseSelectRef>}
        className={clsx(
          cls.select,
          mixSelect,
          props.mode === "multiple" && cls.select__multiple
        )}
        popupClassName={clsx(cls.popup, mixPopup)}
        open={openV}
        onDropdownVisibleChange={(openValue) =>
          setOpenV(isMobile && onOpenModal ? false : openValue)
        }
        maxTagCount={props.mode === "multiple" ? "responsive" : undefined}
        maxTagPlaceholder={(e) => {
          return `Выбрано ${e.length}`;
        }}
        notFoundContent={
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="Введенное значение не найдено. Пожалуйста, выберите из списка"
          />
        }
        bordered={false}
        suffixIcon={
          <Icon
            mix={clsx(cls.icon, open && cls.icon__open)}
            name="chevron-20"
          />
        }
        disabled={disabled}
        dropdownRender={
          dropdownRender
            ? (menu) => {
                return dropdownRender(menu, (e) => {
                  onChange && onChange(e);
                  setOpen(false);
                });
              }
            : undefined
        }
        options={
          options || [
            {
              value: "jack",
              label: "Jack",
            },
            {
              value: "lucy",
              label: "Lucy",
            },
            {
              value: "Yiminghe",
              label: "yiminghe",
            },
          ]
        }
        onClick={(e) => {
          if (onOpenModal && isMobile) {
            e.preventDefault();
            e.stopPropagation();
            onOpenModal(value ?? [], options, onChange);
          }
        }}
        {...props}
        showSearch={onOpenModal && isMobile ? false : props.showSearch}
        allowClear={onOpenModal && isMobile ? false : props.allowClear}
      />
      {label && (
        <label
          className={clsx(
            cls.label,
            (value || open) && cls.label__active,
            open && cls.label__focus,
            message && cls.label__error
          )}
        >
          {label}
        </label>
      )}
      <fieldset
        className={clsx(
          cls.fieldset,
          message && cls.fieldset__error,
          open && cls.fieldset__focus
        )}
      >
        <legend
          className={clsx(
            cls.legend,
            (value || open) && cls.legend__active,
            message && cls.legend__error
          )}
        >
          {label}
        </legend>
      </fieldset>
    </div>
  );
};
