import classNames from 'classnames';
import React, { FC, Fragment, useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';

import styles from './index.module.scss';

export interface ITooltip {
	id: number | string;
	position?: 'right' | 'left' | 'top' | 'bottom';
	title?: string;
	message: any;
	html?: boolean;
	children?: any;
	classes?: {
		title?: string;
		message?: string;
		action?: string;
		buttons?: string;
		box?: string;
	};
}

let single: ReactTooltip | null = null;

const Tooltip: FC<ITooltip> = ({ id, children, position = 'bottom', message, title, html = false, classes = {} }) => {
	const ref = useRef<ReactTooltip>(null);

	const hide = (): void => {
		if (ref.current) {
			ref.current.setState({ show: false });
		}
	};

	const afterShow = (): void => {
		if (single === ref.current) return;

		if (single) {
			single.setState({ show: false });
		}

		single = ref.current;
	};

	useEffect(() => {
		document.addEventListener('scroll', hide, true);

		return () => {
			document.removeEventListener('scroll', hide);
		};
	}, []);

	const CustomComponent: FC = () => {
		return (
			<Fragment>
				{title ? <span className={classNames(styles.title, classes.title)}>{title}</span> : null}
				{html ? (
					<div className={classNames(styles.message, classes.message)} dangerouslySetInnerHTML={{ __html: message }} />
				) : (
					<span className={classNames(styles.message, classes.message)}>{message}</span>
				)}
			</Fragment>
		);
	};

	return (
		<Fragment>
			<div
				data-for={String(id)}
				className={classNames(styles.tooltip, classes.action)}
				data-padding="12px"
				data-place={position}
				data-border={true}
				data-border-color="#F2F2F7"
				data-background-color="#FFFFFF"
				data-border-class={styles.container}
				data-tip={false}
			>
				{children}
			</div>
			<ReactTooltip
				afterShow={afterShow}
				offset={{ left: -3 }}
				id={String(id)}
				ref={ref}
				clickable={true}
				type="light"
				globalEventOff="click"
				event="click"
				effect="solid"
				className={classNames(styles.custom, classes.box)}
			>
				<CustomComponent />
			</ReactTooltip>
		</Fragment>
	);
};

export default Tooltip;
