import { Input } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { TextAreaProps } from "antd/lib/input";
import clsx from "clsx";
import { FC, useState } from "react";

import cls from "./index.module.scss";

interface ITextAreaForm extends TextAreaProps {
  getFieldError?: (name: NamePath) => string[];
  name: NamePath | any;
  label?: string;
  mix?: string;
}

export const TextAreaForm: FC<ITextAreaForm> = ({
  value,
  onChange,
  getFieldError,
  label,
  name,
  mix,
  ...props
}) => {
  const message = getFieldError
    ? typeof getFieldError(name) === "string"
      ? getFieldError(name)
      : getFieldError(name)[0]
    : "";

  const [open, setOpen] = useState(false);

  return (
    <div className={clsx(cls.textarea, mix)}>
      <Input.TextArea
        onChange={onChange}
        value={value}
        status={message ? "error" : ""}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        bordered={false}
        {...props}
      />
      {label && (
        <label
          className={clsx(
            cls.label,
            (value || open) && cls.label__active,
            open && cls.label__focus,
            message && cls.label__error
          )}
        >
          {label}
        </label>
      )}
      <fieldset
        className={clsx(
          cls.fieldset,
          message && cls.fieldset__error,
          open && cls.fieldset__focus
        )}
      >
        <legend
          className={clsx(
            cls.legend,
            (value || open) && cls.legend__active,
            message && cls.legend__error
          )}
        >
          {label}
        </legend>
      </fieldset>
    </div>
  );
};
