import { getCurrentBreakpoint } from "../helpers/getCurrentBreakpoint";
import { useCallback, useEffect, useState } from "react";

export type Breakpoint = "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

export const useBreakpoint = (): Breakpoint => {
  const [breakpoint, setBreakpoint] = useState<Breakpoint>(
    getCurrentBreakpoint()
  );

  const handler = useCallback((): void => {
    const current = getCurrentBreakpoint();

    if (current !== breakpoint) {
      setBreakpoint(current);
    }
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener("resize", handler);
    return () => window.removeEventListener("resize", handler);
  }, [handler]);

  return breakpoint;
};
