import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Download: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="17"
			viewBox="0 0 16 17"
			fill="none"
		>
			<path
				d="M11.6001 6.9L8.0001 10.5M8.0001 10.5L8.0001 2.1M8.0001 10.5L4.4001 6.9"
				stroke="#777778"
				strokeWidth="1.8"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
			<path
				d="M2.3999 11.2998V12.8998C2.3999 14.0044 3.29533 14.8998 4.3999 14.8998H11.5999C12.7045 14.8998 13.5999 14.0044 13.5999 12.8998V11.2998"
				stroke="#777778"
				strokeWidth="1.8"
				strokeLinecap="square"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
