import clsx from "clsx";
import { FC } from "react";
import { InputPhone } from "ui-kit-euroopt";

import cls from "./index.module.scss";

interface IInputPhoneForm {
  getFieldError?: (name: string) => string[];
  label: string;
  disabled?: boolean;
  value?: string;
  onChange?: (e: any) => void;
  mix?: string;
  name: string;
}

export const InputPhoneForm: FC<IInputPhoneForm> = ({
  value,
  onChange,
  getFieldError,
  label,
  disabled,
  mix,
  name,
}) => {
  const message = getFieldError
    ? typeof getFieldError(name) === "string"
      ? getFieldError(name)
      : getFieldError(name)[0]
    : "";
  return (
    <InputPhone
      placeholder=" (99) 999-99-99"
      value={value}
      onChange={onChange ? onChange : () => console.log()}
      disabled={disabled}
      label={label}
      isError={!!message}
      mix={clsx(cls.wrapper, mix, !!message && cls.shake__animation)}
    />
  );
};
