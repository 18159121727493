import { FC } from 'react';
import { Title } from '../title';
import styles from './index.module.scss';
import { Button } from '../button';
import { goTo } from '../../helpers/goTo';
import { Cross } from '../icons/cross';
import items from '../../json/what.json';
import { Download } from '../icons/download';

export const What: FC = () => {
	return (
		<section className={styles.what}>
			<div className={styles.top}>
				<div className={styles.left}>
					<Title title="Что продавать?" />
					<p className={styles.text}>Мы бы хотели продавать всё, но есть несколько ограничений:</p>
				</div>
				<img src="/assets/images/busel-what-to-sell.png" alt="Что продавать" className={styles.image} />
			</div>
			<div className={styles.bottom}>
				{items.map(({ title, text }, index) => (
					<div key={index} className={styles.item}>
						<span className={styles.title}>
							<Cross className={styles.cross} />
							{title}
						</span>
						{text && <span className={styles.description}>{text}</span>}
					</div>
				))}
			</div>
			<div className={styles.requirements}>
				<span className={styles.requirements__text}>
				По умолчанию мы с вами действуем в рамках требований двух нормативных документов
				</span>
				<div className={styles.requirements__links}>
					<a className={styles.requirements__link} target="_blank" href="/assets/files/postanovlenie_sovmina_ot_15_01_2009.pdf">
						<Download className={styles.requirements__icon} />
						Правила продажи товаров при осуществлении розничной торговли по образцам
					</a>
					<a className={styles.requirements__link} target="_blank" href="/assets/files/predmety_i_veshchestva_zapreshchennye_k_peresylke_v_pochtovyh_otpravleniyah.pdf">
						<Download className={styles.requirements__icon} />
						Требования к оказанию услуг почтовой связи
					</a>
				</div>
			</div>
			<Button
				onClick={goTo.registration}
				text="Стать продавцом"
				type="primary"
				size="large"
				className={styles.button}
			/>
		</section>
	);
};
