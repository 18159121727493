import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Cross: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
		>
			<rect width="32" height="32" rx="16" fill="#F4F4F5" />
			<path
				d="M9 9L16 16M23 23L16 16M16 16L23 9M16 16L9 23"
				stroke="#B5B5B8"
				strokeWidth="2.8"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
