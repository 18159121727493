import React, { createContext, useReducer, Dispatch, ReactNode, useContext } from 'react';
import { ActionType, reducer } from './reducer';
import dataFAQ from '../../components/pages/faq/mockData';

interface IStateProviderFaq {
	children: ReactNode;
}

export interface ISearchItem {
	title: string;
	subTitle: string;
	label: string;
	key: string;
	subKey: number;
}

export interface IMenu {
	activeKey: number;
	activeSubKey: number | null;
	activeKeys: string[];
}

export interface IState {
	search: {
		isOpenSearch: boolean;
		isOnPageSearch: boolean;
		value: string;
		error: string;
		allItems: ISearchItem[];
		items: ISearchItem[];
		page: number;
	};
	isOpenMobMenu: boolean;
	menu: IMenu;
}

const getSearchItems = (): ISearchItem[] => {
	let newItems: ISearchItem[] = [];

	dataFAQ.forEach(({ label, items, key }) => {
		if (!items?.length) return [];
		items.forEach(({ title, subTitle }, i) => newItems.push({ title, subTitle, label, key, subKey: i }));
	});
	return newItems;
};

export const initialState: IState = {
	search: {
		isOpenSearch: false,
		isOnPageSearch: false,
		value: '',
		error: '',
		allItems: getSearchItems(),
		items: [],
		page: 1,
	},
	isOpenMobMenu: false,
	menu: {
		activeKeys: ['0'],
		activeKey: 0,
		activeSubKey: null,
	},
};

export interface IContextProps {
	state: IState;
	dispatch: Dispatch<ActionType>;
}

export const Context = createContext<IContextProps | undefined>(undefined);

export const StateProviderFaq: React.FC<IStateProviderFaq> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export const useStateFaq = () => {
	const context = useContext(Context);
	if (!context) throw new Error('useStateFaq не в StateProviderFaq');
	return context;
};
