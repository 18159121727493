import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Card: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<g clipPath="url(#clip0_518_1634)">
				<path
					d="M0 2H18C20.2091 2 22 3.79086 22 6V18C22 20.2091 20.2091 22 18 22H0"
					stroke="#F4303B"
					strokeWidth="2.8"
				/>
				<path d="M14 16.5L15.5 16.5" stroke="#F4303B" strokeWidth="2.8" strokeLinecap="square" />
				<path d="M-2 16.5L8.5 16.5" stroke="#F4303B" strokeWidth="2.8" strokeLinecap="square" />
				<path d="M-1.5 8H22" stroke="#F4303B" strokeWidth="2.8" strokeLinecap="round" />
			</g>
			<defs>
				<clipPath id="clip0_518_1634">
					<rect width="24" height="24" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
