import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Chevron: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.5 15.5L12.7071 7.70711C12.3166 7.31658 11.6834 7.31658 11.2929 7.70711L3.5 15.5"
				stroke="#1C1C1E"
				strokeWidth="2.8"
				strokeLinecap="square"
			/>
		</svg>
	);
};
