import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Burger: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path d="M3 6.5H21" stroke="#1C1C1E" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
			<path d="M3 17.5H21" stroke="#1C1C1E" strokeWidth="2" strokeLinecap="square" strokeLinejoin="round" />
		</svg>
	);
};
