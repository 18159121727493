import clsx from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useIsMobile } from '../../../../hooks/useIsMobile';

import dataFAQ, { IDataSidebar } from '../mockData';
import cls from './index.module.scss';
import { useStateFaq } from '../../../../store/faq/context';

interface IProps {
	item: IDataSidebar;
}

const HelpInfo: FC<IProps> = ({ item }) => {
	const isMobile = useIsMobile('sm');
	const { items, label, key, bgColor } = item;
	const [isHide, setIsHide] = useState(true);
	const isShowButton = !!(isMobile && isHide && items && items.length > 3);

	const {
		state: {
			menu: { activeKey },
		},
		dispatch,
	} = useStateFaq();

	useEffect(() => setIsHide(true), [activeKey]);

	return activeKey === 0 ? (
		label === 'База знаний' ? null : (
			<>
				<h2 className={cls.heading}>{label}</h2>
				<div className={clsx(cls.blockForNew, { [cls.blockForNew__inner]: isShowButton })}>
					{items?.map(({ img, title, subTitle }, i) =>
						isMobile && isHide && i >= 3 ? null : (
							<div
								key={title}
								onClick={() => {
									setIsHide(true);
									dispatch({ type: 'SET_CATEGORY', payload: { key, subKey: i } });
								}}
								className={clsx(cls.wrapper, cls[bgColor as string], { [cls.wrapper__inner]: img })}
								style={img ? { backgroundImage: `url(/assets/images/${img})` } : undefined}
							>
								<h3>{title}</h3>
								<div>
									<span>{subTitle}</span>
								</div>
							</div>
						),
					)}
				</div>
				{isShowButton && (
					<button className={cls.link} onClick={() => setIsHide(false)} type="button">
						Показать ещё {items.length - 3}
					</button>
				)}
			</>
		)
	) : (
		<>
			<h2 className={cls.heading}>{dataFAQ[activeKey as number]?.label}</h2>
			<div className={cls.blockForNew}>
				{dataFAQ[activeKey as number]?.items?.map(({ img, title, subTitle }, i) => (
					<div
						key={title}
						onClick={() => dispatch({ type: 'SET_ACTIVE_SUB_KEY', payload: i })}
						className={clsx(cls.wrapper, cls[dataFAQ[activeKey as number].bgColor as string], {
							[cls.wrapper__inner]: img,
						})}
						style={img ? { backgroundImage: `url(/assets/images/${img})` } : undefined}
					>
						<h3>{title}</h3>
						<div>
							<span>{subTitle}</span>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default HelpInfo;
