import { FC } from "react";
import { Checkbox } from "ui-kit-euroopt";

interface ICheckBoxForm {
  getFieldError?: (name: string) => string[];
  label?: string;
  disabled?: boolean;
  value?: string | boolean;
  onChange?: (e: any) => void;
  submit?: () => void;
  mix?: string;
  title: any;
}

export const CheckBoxForm: FC<ICheckBoxForm> = ({
  value,
  onChange,
  title,
  disabled,
  mix,
  ...props
}) => {
  return (
    <Checkbox
      checked={!!value}
      onChange={onChange}
      disabled={disabled}
      title={title}
      mix={mix}
      {...props}
    />
  );
};
