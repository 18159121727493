import { FC, useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { Input } from '../input';

const deliveryValues = [
	{
		min: 0.001,
		max: 0.5,
		price: 1.88,
	},
	{
		min: 0.51,
		max: 1,
		price: 3.79,
	},
	{
		min: 1.001,
		max: 2,
		price: 4,
	},
	{
		min: 2.001,
		max: 5,
		price: 4.99,
	},
	{
		min: 5.001,
		max: 10,
		price: 6.49,
	},
	{
		min: 10.001,
		max: 15,
		price: 8.59,
	},
	{
		min: 15.001,
		max: 20,
		price: 10.30,
	},
	{
		min: 20.001,
		max: 25,
		price: 12.30,
	},
	{
		min: 25.001,
		max: 30,
		price: 14.50,
	},
];

export const Calc: FC = () => {
	const [price, setPrice] = useState(30);
	const [weight, setWeight] = useState(1);
	const [reward, setReward] = useState(0);
	const [delivery, setDelivery] = useState(0);
	const [total, setTotal] = useState(0);
	const [totalPercent, setTotalPercent] = useState(0);

	const calc = useCallback(() => {
		const deliveryValuesResult = deliveryValues.find(({ min, max }) => weight >= min && weight <= max);
		const deliveryValue = deliveryValuesResult ? deliveryValuesResult.price : 0;
		const rewardValue = Number((price * 0.04).toFixed(2));
		const totalValue = Number((rewardValue + deliveryValue).toFixed(2));
		const percentValue = Number(((totalValue / price) * 100).toFixed(1));

		setTotalPercent(percentValue);
		setDelivery(deliveryValue);
		setReward(rewardValue);
		setTotal(totalValue);
	}, [price, weight]);

	useEffect(() => {
		calc();
	}, [calc, price, weight]);

	return (
		<div className={styles.calc}>
			<span className={styles.badge}>Это {totalPercent}% от цены товара</span>
			<div className={styles.inputs}>
				<div className={styles.item}>
					<span className={styles.label}>Цена товара</span>
					<Input onChange={setPrice} value={price} label="р." min={1} max={1000000} step={0.01} fraction={2} />
				</div>
				<div className={styles.item}>
					<span className={styles.label}>Вес товара</span>
					<Input onChange={setWeight} value={weight} label="кг" min={0.001} max={30} step={0.001} fraction={3} />
				</div>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Вознаграждение emall</span>
				<span className={styles.value}>{reward} р.</span>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Доставка</span>
				<span className={styles.value}>{delivery} р.</span>
			</div>
			<div className={styles.item}>
				<span className={styles.label}>Итого</span>
				<span className={styles.value}>{total} р.</span>
			</div>
		</div>
	);
};
