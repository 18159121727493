import { IFeedbackGetData, IFeedbackResponse } from '../../../api/feedback/interface/feedback.interface';
import { CheckBoxForm } from '../../form/CheckBoxForm';
import { InputForm } from '../../form/InputForm';
import { InputPhoneForm } from '../../form/InputPhoneForm';
import { SelectForm } from '../../form/SelectForm';
import { TextAreaForm } from '../../form/TextAreaForm';
import { UploadForm } from '../../form/UploadForm';
import { openModal } from '../anyModal';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import Modal from 'antd/lib/modal/Modal';
import { serialize } from 'object-to-formdata';
import { FC, useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { Btn } from 'ui-kit-euroopt';

import cls from './index.module.scss';
import { getFeedbackTarget, getFeedbackTypes, setFeedbackData } from '../../../api/feedback';

let node: any = null;

type Data = {
	cb: () => void;
} | null;

interface IModalFeedback {
	cb: (e?: unknown) => void;
	isPublic?: boolean;
}

const ModalFeedback: FC<IModalFeedback> = ({ cb }) => {
	const [showModal, setShowModal] = useState<boolean>(false);
	const [hideModal, setHideModal] = useState<boolean>(false);
	const [optionsSelectTypes, setOptionsSelectTypes] = useState<IFeedbackGetData[]>([]);
	const [optionsSelectTarget, setOptionsSelectTarget] = useState<IFeedbackGetData[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const [form] = useForm();

	const onClose = (): void => {
		setHideModal(true);
		cb();
		setTimeout(() => {
			try {
				node && document.body.removeChild(node);
			} catch (e) {
				// ignore
			}
			document.body.style.overflow = '';
			node = null;
		}, 400);
	};

	const getTypesFeedback = async (): Promise<void> => {
		try {
			const data = await getFeedbackTypes();
			setOptionsSelectTypes(data);
		} catch (e) {
			console.log(e);
		}
	};

	const getTargetFeedback = async (id: any): Promise<void> => {
		try {
			const data = await getFeedbackTarget(id);
			setOptionsSelectTarget(data);
			form.setFieldValue('request_target_id', '');
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		getTypesFeedback();
		setShowModal(true);
	}, []);

	const onFinish = async (e: IFeedbackResponse): Promise<void> => {
		if (!e.files) {
			e.files = [];
		}
		try {
			setIsLoading(true);
			e.files = e.files.map((el: any) => el.file);
			e.need_response = Number(Boolean(e.need_response));
			const formData = serialize(e);
			const { RequestId } = await setFeedbackData(formData);
			onClose();
			await openModal({
				className: cls.wrapper__modal,
				iconName: 'check-24',
				title: `Обращение ${RequestId ? `№${RequestId}` : ''} отправлено`,
				description: 'Оно будет рассмотрено в течение 24 часов. Ответ придёт на указанную электронную почту.',
				titleReject: 'Хорошо',
			});
		} catch (err: any) {
			console.log(err);
			const errors = err.errors ?? {};
			const isImgErr = Object.keys(errors).some((key) => key.startsWith('file'));
			const fields = Object.keys(errors).map((key) => {
				return {
					name: key,
					errors: errors[key] ?? 'упс..',
				};
			});

			if (isImgErr) {
				fields.push({
					name: 'files',
					errors: ['В этом поле должен быть загружены картинки форматов: jpeg,png'],
				});
			}

			form.setFields(fields);
		} finally {
			setIsLoading(false);
		}
	};

	return showModal ? (
		<Modal
			footer={null}
			centered
			keyboard={false}
			destroyOnClose
			maskClosable={false}
			wrapClassName={cls.wrapper__modalMain}
			className={cls.modalStyle}
			open={!hideModal}
			onCancel={onClose}
		>
			<h2 className={cls.wrapper__h2}>Обратная связь</h2>
			<Form form={form} className={cls.form} onFinish={onFinish}>
				<Form.Item noStyle name="files.0" />
				<Form.Item noStyle name="files.2" />
				<Form.Item noStyle name="files.3" />
				<Form.Item noStyle name="files.4" />
				<Form.Item noStyle name="files.1" />
				<Form.Item required rules={[{ required: true, message: 'Обязательное поле' }]} name="types_id" colon={false}>
					<SelectForm
						options={optionsSelectTypes.map((el) => ({
							value: el.id,
							label: el.name,
						}))}
						onChange={getTargetFeedback}
						name="types_id"
						getFieldError={form.getFieldError}
						label="Тема обращения *"
					/>
				</Form.Item>
				{!!optionsSelectTarget.length && (
					<Form.Item
						rules={[{ required: true, message: 'Обязательное поле' }]}
						required
						name="request_target_id"
						colon={false}
					>
						<SelectForm
							options={optionsSelectTarget.map((el) => ({
								value: el.id,
								label: el.name,
							}))}
							name="request_target_id"
							getFieldError={form.getFieldError}
							label="Предмет обращения *"
						/>
					</Form.Item>
				)}
				<Form.Item required rules={[{ required: true, message: 'Обязательное поле' }]} name="name" colon={false}>
					<InputForm name="name" label="Имя *" getFieldError={form.getFieldError} />
				</Form.Item>
				<Form.Item required rules={[{ required: true, message: 'Обязательное поле' }]} name="email">
					<InputForm name="email" label="Email *" getFieldError={form.getFieldError} />
				</Form.Item>
				<Form.Item required rules={[{ required: true, message: 'Обязательное поле' }]} name="phone" colon={false}>
					<InputPhoneForm label="Номер телефона" name="phone" getFieldError={form.getFieldError} />
				</Form.Item>
				<Form.Item required rules={[{ required: true, message: 'Обязательное поле' }]} name="comment" colon={false}>
					<TextAreaForm
						mix={cls.textArea}
						maxLength={1000}
						showCount={true}
						label="Сообщение *"
						name="comment"
						getFieldError={form.getFieldError}
					/>
				</Form.Item>
				<div className={cls.bottom__wrapper}>
					<Form.Item
						shouldUpdate={(prevValues, currentValues) =>
							prevValues.files !== currentValues.files ||
							prevValues.file0 !== currentValues.file0 ||
							prevValues.file1 !== currentValues.file1 ||
							prevValues.file2 !== currentValues.file2 ||
							prevValues.file3 !== currentValues.file3 ||
							prevValues.file4 !== currentValues.file4
						}
						required={false}
						noStyle
					>
						{({ getFieldValue, getFieldError, setFields }) => {
							const lengthFiles = getFieldValue('files')?.length;
							return (
								<Form.Item name="files" colon={false}>
									<UploadForm
										className={cls.upload__files}
										mix={cls.upload__files_img}
										multiple
										name="files"
										names={['files.0', 'files.1', 'files.2', 'files.3', 'files.4']}
										getFieldError={getFieldError}
										setFields={setFields}
										onChange={() => setFields([{ name: 'files', errors: [] }])}
										text={lengthFiles !== 5 ? `+ Добавить фото (${5 - (lengthFiles || 0)})` : ''}
										removeText={true}
									/>
								</Form.Item>
							);
						}}
					</Form.Item>
					<Form.Item name="need_response" colon={false}>
						<CheckBoxForm title="Требуется обратная связь" />
					</Form.Item>
					<span className={cls.text}>
						Отправляемое электронное сообщение не относится к сфере действия Закона Республики Беларусь от 18.07.2011 №
						300-3 «Об обращениях граждан и юридических лиц» на основании абзаца 9 статьи 1, пункта 1 статьи 3, пункта 1
						статьи 25 данного Закона. Ориентировочное время ответа 1-3 дня
					</span>
				</div>
				<div className={cls.wrapper__button}>
					<Btn size="large" colour="white" onClick={onClose} text="Отмена" mix={cls.cancel__btn} />
					<Btn
						size="large"
						htmlType="submit"
						colour="primary"
						text="Отправить"
						disabled={isLoading}
						loading={isLoading}
					/>
				</div>
			</Form>
		</Modal>
	) : null;
};

ModalFeedback.defaultProps = {
	cb: () => {
		console.log('submit');
	},
};

export const openModalFeedback = async (isPublic?: boolean): Promise<Data | undefined> => {
	try {
		return new Promise((res) => {
			if (!node) {
				node = document.createElement('div');
				node.id = 'renderModal';
				document.body.appendChild(node);
				document.body.style.overflow = 'hidden';
			}
			const root = createRoot(node);
			root.render((<ModalFeedback isPublic={isPublic} cb={res as () => void} />) as any);
		});
	} catch (e) {
		console.log(e);
	}
};
