import {TGetContactPerson, TGetReviewAnswer, TPostReviewAnswer} from "./interface/review-answer.interface";
import axios from "axios";

// const baseUrl = "https://api-test6.emall.by/api/v1";
const baseUrl = "https://api.emall.by/api/v1";

export const getReviewAnswer: TGetReviewAnswer = async (id: string) => {
    const res = await axios.get(`${baseUrl}/review-answer/review?uuid=${id}`);
    return res.data;
}

export const responseContactPerson: TGetContactPerson = async (id: number) => {
    const res = await axios.get(`${baseUrl}/review-answer/request-contacts?review_id=${id}`);
    return res.data;
}

export const postReviewAnswer: TPostReviewAnswer = async (payload: TPostReviewAnswer) => {
    const res = await axios.post(`${baseUrl}/review-answer/`,  payload)
    return res.data;
}