import { Button } from '../../../button';
import { openModalFeedback } from '../../../modals/feedbackModal';
import styles from './index.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Button onClick={openModalFeedback} text="Связаться с нами" type="secondary" size="medium"/>
      <a target="_blank" href="https://t.me/emallby" className={styles.telegram_link} rel="noreferrer">
								<img width={24} height={24} src="/assets/images/telegram.svg" alt="Телеграм" />
                 Телеграм канал
							</a>
    </footer>
  )
}