import { FC } from 'react';
import { Title } from '../../../title';
import items from '../../../../json/how-work.json';
import styles from './index.module.scss';

export const HowWork: FC = () => {
	return (
		<section className={styles.how}>
			<Title title="Как всё работает" />
			{items.map(({ title, text, imageUrl }, index) => (
					<figure className={styles.item} key={index}>
						<img src={imageUrl} className={styles.image} alt={title} />
						<figcaption className={styles.content}>
							<span className={styles.title}>{title}</span>
							<span className={styles.text}>{text}</span>
						</figcaption>
					</figure>
			))}
		</section>
	);
};
