import { ISearchItem, IState, initialState } from './context';

export type ActionType =
	| { type: 'SET_SEARCH'; payload: string }
	| { type: 'SET_SEARCH_ERROR'; payload: string }
	| { type: 'SET_IS_OPEN_SEARCH'; payload: boolean }
	| { type: 'SET_IS_ON_PAGE_SEARCH'; payload: boolean }
	| { type: 'SET_IS_OPEN_MOBMENU'; payload: boolean }
	| { type: 'CANCEL_SEARCH'; payload?: null }
	| { type: 'SET_ACTIVE_KEYS'; payload: string[] }
	| { type: 'SET_ACTIVE_KEY'; payload: number }
	| { type: 'SET_ACTIVE_SUB_KEY'; payload: number | null }
	| { type: 'SET_SEARCH_PAGE'; payload: number }
	| { type: 'SET_CATEGORY'; payload: { key: string; subKey: number | null } };

export const reducer = (state: IState, action: ActionType) => {
	const { type, payload } = action;

	const setSearchItems = (search: string): ISearchItem[] => {
		return state.search.allItems.filter(({ title, subTitle }) => {
			return (
				title.toLowerCase().includes(search.toLowerCase()) || subTitle.toLowerCase().includes(search.toLowerCase())
			);
		});
	};

	switch (type) {
		case 'SET_SEARCH':
			return {
				...state,
				search: {
					...state.search,
					error: payload.length > 0 && payload.length < 3 ? 'Введите минимум 3 символа' : '',
					value: payload,
					items: payload ? setSearchItems(payload) : state.search.allItems,
					page: 1,
				},
			};
		case 'SET_SEARCH_ERROR':
			return { ...state, search: { ...state.search, error: payload, items: [] } };
		case 'SET_IS_OPEN_SEARCH':
			document.body.style.overflow = payload ? 'hidden' : '';
			document.body.style.touchAction = payload ? 'none' : 'initial';
			document.body.style.position = payload ? 'fixed' : 'initial';
			document.body.style.top = payload ? '0' : 'initial';
			document.body.style.bottom = payload ? '0' : 'initial';
			return {
				...state,
				search: {
					...state.search,
					isOpenSearch: payload,
					isOnPageSearch: false,
					items: payload ? setSearchItems(state.search.value) : state.search.items,
				},
			};
		case 'SET_IS_ON_PAGE_SEARCH':
			document.body.style.overflow =  '';
			document.body.style.touchAction = 'initial';
			document.body.style.position =  'initial';
			document.body.style.top =  'initial';
			document.body.style.bottom =  'initial';
			return {
				...state,
				search: {
					...state.search,
					isOpenSearch: false,
					isOnPageSearch: payload,
					items: payload ? setSearchItems(state.search.value) : state.search.items,
				},
			};
		case 'SET_IS_OPEN_MOBMENU':
			return { ...state, isOpenMobMenu: payload };
		case 'CANCEL_SEARCH':
			document.body.style.overflow =  '';
			document.body.style.touchAction = 'initial';
			document.body.style.position =  'initial';
			document.body.style.top =  'initial';
			document.body.style.bottom =  'initial';
			return { ...state, search: initialState.search };
		case 'SET_SEARCH_PAGE':
			return { ...state, search: { ...state.search, page: payload } };
		case 'SET_ACTIVE_KEYS':
			return { ...state, menu: { ...state.menu, activeKeys: payload } };
		case 'SET_ACTIVE_KEY':
			return { ...state, menu: { ...state.menu, activeKey: payload } };
		case 'SET_ACTIVE_SUB_KEY':
			return { ...state, menu: { ...state.menu, activeSubKey: payload } };
		case 'SET_CATEGORY':
			const { key, subKey } = payload;
			const activeKeys = state.menu.activeKeys;

			return {
				...state,
				search: initialState.search,
				isOpenMobMenu: false,
				menu: {
					...state.menu,
					activeKey: +key,
					activeKeys: !activeKeys.includes(key) ? [...activeKeys, key] : activeKeys,
					activeSubKey: subKey,
				},
			};
		default:
			return state;
	}
};
