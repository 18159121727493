import 'overlayscrollbars/overlayscrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { Collapse } from 'antd';
import clsx from 'clsx';
import { Icon } from 'ui-kit-euroopt';

import dataFAQ from '../mockData';
import style from './index.module.scss';
import { useStateFaq } from '../../../../store/faq/context';
import { openModalFeedback } from '../../../modals/feedbackModal';

const { Panel } = Collapse;

const Sidebar = () => {
	const {
		state: {
			isOpenMobMenu,
			search: { isOnPageSearch },
			menu: { activeKey, activeSubKey, activeKeys },
		},
		dispatch,
	} = useStateFaq();

	return (
		<>
			<div
				onClick={() => dispatch({ type: 'SET_IS_OPEN_MOBMENU', payload: false })}
				className={clsx(style.overlay, { [style.active]: isOpenMobMenu })}
			/>
			<div className={clsx(style.wrapperLeftCol, { [style.active]: isOpenMobMenu })}>
				<OverlayScrollbarsComponent className={style.scroll} options={{ scrollbars: { autoHide: 'move' } }}>
					<Collapse
						collapsible="icon"
						expandIcon={({ isActive }) => (
							<Icon mix={clsx(style.icon, { [style.icon__active]: isActive })} size={12} name="chevron-12" />
						)}
						activeKey={activeKeys}
						className={style.collapse}
						onChange={(key) => dispatch({ type: 'SET_ACTIVE_KEYS', payload: typeof key === 'string' ? [key] : key })}
						ghost
					>
						{dataFAQ.map(({ items, label, key }, i) => (
							<Panel
								className={style.panel}
								header={
									<p
										onClick={() => dispatch({ type: 'SET_CATEGORY', payload: { key, subKey: null } })}
										className={clsx(style.headerText, {
											[style.activeItem]: !isOnPageSearch && activeKey === +key && activeSubKey === null,
										})}
									>
										{label}
									</p>
								}
								key={+key}
								showArrow={!!items}
							>
								{activeKeys.includes(key) && items && (
									<div className={style.panelContent}>
										{items.map((el: any, j) => (
											<div
												onClick={() => dispatch({ type: 'SET_CATEGORY', payload: { key: `${i}`, subKey: j } })}
												className={clsx(style.item, {
													[style.activeItem]: !isOnPageSearch && activeKey === +key && activeSubKey === j,
												})}
												key={el.title}
											>
												<span>{el.title}</span>
											</div>
										))}
									</div>
								)}
							</Panel>
						))}
					</Collapse>
					<p className={style.notice}>
					Если у вас остались вопросы,{' '}
					<span
						onClick={() => {
							dispatch({ type: 'SET_IS_OPEN_MOBMENU', payload: false });
							openModalFeedback(false);
						}}
						className={style.link}
					>
						напишите
					</span>{' '}
					нам
				</p>
				</OverlayScrollbarsComponent>
			</div>
		</>
	);
};

export default Sidebar;
