import clsx from "clsx";
import { ButtonHTMLAttributes, FC } from "react";

import cls from "./index.module.scss";

type ITouchable = ButtonHTMLAttributes<any> & { mix?: string };

export const Touchable: FC<ITouchable> = ({ children, mix, ...props }) => (
  <button type="button" className={clsx(cls.button, mix)} {...props}>
    {children}
  </button>
);
