import {TGetFeedbackTarget, TGetFeedbackTypes, TPostFeedback,} from "./interface/feedback.interface";

import {request} from "../../request";

export const getFeedbackTypes: TGetFeedbackTypes = async () => {
  const response = await request.get(`/feedback/types`);
  return response.data;
};

export const getFeedbackTarget: TGetFeedbackTarget = async (id) => {
  const response = await request.get(`/feedback/targets/${id}`);
  return response.data;
};

export const setFeedbackData: TPostFeedback = async (data) => {
  const response = await request.postForm(`/feedback`, data);
  return response.data;
};

export const setAuthorizedFeedbackData: TPostFeedback = async (data) => {
  const response = await request.postForm(`/authorized-feedback`, data);
  return response.data;
};
