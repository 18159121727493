import Modal from "antd/lib/modal/Modal";
import clsx from "clsx";
import {FC, ReactNode, useEffect, useState} from "react";
import {createRoot} from "react-dom/client";
import {Btn, Icon} from "ui-kit-euroopt";
import {TIcon} from "ui-kit-euroopt/src/components/icon/icon.interface";

import cls from "./index.module.scss";

let node: any = null;

type Data = {
  success?: () => void;
  reject?: () => void;
  title: string;
  titleSuccess?: string;
  titleReject?: string;
  description?: ReactNode;
  iconName?: TIcon;
  className?: string;
  wrapperClassName?: string;
};

interface IModalAddFile {
  res: (e?: unknown) => void;
  rej: (e?: unknown) => void;
  data: Data;
}

const ModalAddFile: FC<IModalAddFile> = ({ res, rej, data }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [hideModal, setHideModal] = useState<boolean>(false);
  const onClose = (): void => {
    setHideModal(true);
    setTimeout(() => {
      try {
        node && document.body.removeChild(node);
      } catch (e) {
        // ignore
      }
      document.body.style.overflow = "";
      node = null;
    }, 400);
  };
  useEffect(() => {
    setShowModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccess = (e: any): void => {
    res(e);
    data?.success && data.success();
    onClose();
  };

  const onReject = (e: any): void => {
    rej(e);
    data?.reject && data.reject();
    onClose();
  };

  return showModal ? (
    <Modal
      footer={null}
      centered
      closable={false}
      keyboard={false}
      destroyOnClose
      maskClosable={false}
      className={clsx(cls.modalStyle, data.wrapperClassName)}
      open={!hideModal}
      onCancel={onReject}
    >
      <div className={clsx(cls.wrapper, data.className)}>
        {data.iconName && <Icon name={data.iconName} />}
        <h2 className={cls.wrapper__h2}>{data.title}</h2>
        {data.description && (
          <span className={cls.wrapper__text}>{data.description}</span>
        )}
        {data.titleSuccess && (
          <Btn
            colour="primary"
            mix={cls.wrapper__btn2}
            text={data.titleSuccess}
            size="medium"
            onClick={onSuccess}
          />
        )}
        {data.titleReject && (
          <Btn
            colour="white"
            mix={cls.wrapper__btn}
            text={data.titleReject}
            size="medium"
            onClick={onReject}
          />
        )}
      </div>
    </Modal>
  ) : null;
};

export const openModal = async (data: Data): Promise<Data | undefined> => {
  try {
    return new Promise((res, rej) => {
      if (!node) {
        node = document.createElement("div");
        node.id = "renderModal";
        document.body.appendChild(node);
        document.body.style.overflow = "hidden";
      }
      const root = createRoot(node);
      root.render(
        (
          <ModalAddFile
            data={data}
            rej={rej as () => void}
            res={res as () => void}
          />
        ) as any
      );
    });
  } catch (e) {
    console.log(e);
  }
};
