import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Place: FC<IProps> = ({ className }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
		>
			<path
				d="M13.4746 21.1434L18.3163 14.2267C20.6634 10.8738 19.9816 6.27129 16.7634 3.7427C13.9677 1.54604 10.0323 1.54604 7.23656 3.7427C4.01836 6.27129 3.33664 10.8738 5.68368 14.2267L10.5254 21.1434C11.242 22.1671 12.758 22.1671 13.4746 21.1434Z"
				stroke="#F4303B"
				strokeWidth="2.8"
			/>
			<path
				d="M10.5 9.5C10.5 10.3284 11.1716 11 12 11C12.8284 11 13.5 10.3284 13.5 9.5C13.5 8.67157 12.8284 8 12 8C11.1716 8 10.5 8.67157 10.5 9.5Z"
				stroke="#F4303B"
				strokeWidth="2.8"
			/>
		</svg>
	);
};
