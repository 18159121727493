import {FC, useEffect, useState} from "react";
import styles from "./index.module.scss";
import {TextAreaForm} from "../../components/form/TextAreaForm";
import {Form, notification} from "antd";
import {useForm} from "antd/lib/form/Form";
import {getQuestion, sendAnswerQuestion} from "../../api/question-answer";
import {IQuestion} from "../../api/question-answer/interface/question-answer.interface";
import {Btn} from "ui-kit-euroopt";
import {useNavigate, useSearchParams} from "react-router-dom";

export const QuestionAnswer: FC = () => {
    const [data, setData] = useState<IQuestion>()
    const [form] = useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const uuid = searchParams.get('uuid');

    useEffect(() => {
        fetchData(uuid as string)
    }, [])

    const onFinish = async (e: any ) => {
         try {
             await sendAnswerQuestion(uuid as string, e.answer);
             navigate(`/question-answer/thanks-page?uuid=${uuid}`)
         } catch (e) {
             notification.error({message: "Что-то пошло не так", style: {borderRadius: "20px"}})
             console.log(e);
         }
    }

    const fetchData = async (id: string) => {
        try {
            const res = await getQuestion(id);
            setData(res);
        } catch (e: any) {
            if (e.response.data.error === "Ответ на вопрос уже оставлен") {
                navigate(`/question-answer/thanks-page?uuid=${uuid}`)
            } else {
                navigate(`/`)
            }
        }
    }

    const formatDate = (date: string): string => {
        const dateInitial = new Date(date);
        return `${dateInitial.getDate()}.${dateInitial.getMonth() + 1 < 10 ? '0' + dateInitial.getMonth() : dateInitial.getMonth()}.${dateInitial.getFullYear()}, ${dateInitial.getHours()}:${dateInitial.getMinutes()}`
    }

    const handleCopyText = (value: string) => {
        navigator.clipboard.writeText(value);
        notification.success({message: "Скопировано"});
    }

    return data ? (
        <div className={styles.wrapper}>
            <img className={styles.logo}
                 src="/assets/images/logo.svg?v=1"
                 alt="Emall Seller"/>
            <p className={styles.title}>Поступил новый вопрос о товаре</p>
            <div className={styles.wrapperReview}>
                <div className={styles.aboutOffer}>
                    <a style={{display: "contents"}} target="_blank" href={`https://emall.by/product/${data.offer.id}`} rel="noreferrer">
                        {!!data?.offer.image?.original_url && <img
                            className={styles.offerImg}
                            src={data?.offer.image.original_url}
                            alt="offer"
                        />}
                    </a>
                    <div>
                        <p className={styles.name}>
                            <a
                                rel="noreferrer"
                                style={{color: "inherit"}}
                                target="_blank" href={`https://emall.by/product/${data.offer.name}`}
                            >
                                    {data.offer.name}
                            </a>
                        </p>
                        <p className={styles.offerId}>Арт. <span onClick={() => handleCopyText(data?.offer.id.toString())}>{data?.offer.id}</span></p>
                    </div>
                </div>
                <div className={styles.line}></div>
                <div className={styles.review}>
                    <p className={styles.nameReviewer}>{data?.client.fio}{!!data?.created_at && <span> {formatDate(data?.created_at as string)}</span>}</p>
                    <p className={styles.textReview}>{data?.question}</p>
                </div>
            </div>
            <p className={styles.textForTextarea}>Ответ на вопрос о товаре</p>
            <Form onFinish={onFinish} form={form} className={styles.form}>
                <Form.Item className={styles.textarea} name="answer">
                    <TextAreaForm
                        label=""
                        name="answer"
                        maxLength={1000}
                        showCount={true}
                        mix={styles.textareaForm}
                        rows={6}
                    />
                </Form.Item>
                <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.answer !== nextValues.answer}>
                    {({getFieldValue}) => (
                        <Btn
                            text="Отправить ответ"
                            colour="primary"
                            htmlType="submit"
                            size="medium"
                            mix={styles.btnSubmit}
                            disabled={!getFieldValue("answer")}
                        />
                    )}
                </Form.Item>
            </Form>
            <div className={styles.line}></div>
            <div className={styles.infoFooter}>
                <div>
                    <div className={styles.warningIconCustom}>!</div>
                    <p>Важно</p>
                </div>
                <ul>
                    <li>
                        Мы оставляем за собой право не публиковать ваш ответ, если он будет расцениваться как грубый или не относящийся к существу вопроса.
                    </li>
                    <li>Текст вашего сообщения будет опубликован без редакции с нашей стороны на странице товара и в личном кабинете пользователя  после прохождения модерации.</li>
                </ul>
            </div>
        </div>
    ) : null
}