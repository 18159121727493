import React, { ChangeEvent, FC, KeyboardEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';

export interface ICounter {
	label?: string;
	mix?: string;
	fraction?: number;
	onChange: (value: number) => void;
	value: number;
	min: number;
	max: number;
	step: number;
}

const formatValue = (str: string | number, units?: string): string => {
	if (!str) return '';

	const value = String(str).replace(',', '.');
	return units ? value.concat(` ${units.trim()}`) : value.replace(/[^0-9.]/g, '');
};

export const Input: FC<ICounter> = ({ onChange, value, min = 1, max = 1000, step = 1, label, fraction = 0 }) => {
	const input = useRef<HTMLInputElement>(null);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [temporaryValue, setTemporaryValue] = useState<number | string>(value);
	const inputValue = editMode ? formatValue(temporaryValue) : formatValue(value, label);

	const validator = (value: number): number => {
		if (value > max) return max;
		if (value < min) return min;
		return value;
	};

	const change = (value: number): void => {
		onChange(fraction ? Number(value.toFixed(fraction)) : value);
	};

	const inputOnFocus = (e: SyntheticEvent): void => {
		setTemporaryValue(value);
		setEditMode(true);
	};

	const inputOnBlur = (e: SyntheticEvent): void => {
		const number = Number(temporaryValue);

		if (number) {
			change(validator(step * Math.ceil(number / step)));
		}

		setEditMode(false);
	};

	const inputOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setTemporaryValue(e.target.value);
	};

	const inputOnKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>): void => {
		if (key === 'Enter' && input.current) {
			input.current.blur();
		}
	};

	useEffect(() => {
		editMode ? input?.current?.select() : setTemporaryValue(value);
	}, [editMode, value]);

	return (
		<input
			ref={input}
			name="count"
			step={step}
			value={inputValue}
			inputMode="text"
			className={styles.input}
			onFocus={inputOnFocus}
			onBlur={inputOnBlur}
			onChange={inputOnChange}
			onKeyPress={inputOnKeyPress}
			autoComplete="off"
		/>
	);
};
