import { FC } from 'react';
import styles from './index.module.scss';
import {Title} from "../../../title";

const items = [
	{ count: '100 000', text: 'активных пользователей ежемесячно, 75% из которых женщины' },
	{ count: '1 700 000', text: 'единиц товаров продаётся ежемесячно' },
	{ count: '300', text: 'пунктов выдачи заказов в РБ' },
	{ count: '30 000', text: 'собственных товаров на складах' },
];

export const About: FC = () => {
	return (
		<section className={styles.about}>
			<Title title="Про emall" />
			<p className={styles.text}>
			Сегодня emall – это молодой белорусский маркетплейс. А начали мы в декабре 2021 года как первый онлайн-дискаунтер в Беларуси. За 2 года мы существенно трансформировали проект и сейчас имеем:
			</p>
			<div className={styles.items}>
				{items.map(({ count, text }, index) => (
					<div className={styles.item} key={index}>
						<span className={styles.item__title}>
							<span className={styles.item__title_red}>~</span>
							{count}
						</span>
						<span className={styles.item__text}>{text}</span>
					</div>
				))}
			</div>
		</section>
	);
};
