import axios from "axios";

import cookie from "cookiejs";
import {config} from "../config";

export const request = axios.create({
  baseURL: `${config.api.url}/api/b2b/v1`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json;charset=UTF-8",
  },
  responseType: "json",
  timeout: 30000,
  transformRequest: [
    function (data, headers) {
      if (headers && data && data.token) {
        headers["ApiToken"] = `${data.token}`;
      }
      const token = cookie.get("token");

      if (headers && token) {
        headers["ApiToken"] = `${token}`;
      }

      if (data && data instanceof FormData) {
        return data;
      }
      return JSON.stringify(data);
    },
  ],
});

request.interceptors.response.use(
  (response) => response,
  async (error) => {

    throw {
      ...(error?.response?.data ?? {}),
      status: error?.response?.status ?? 0,
    };
  }
);
