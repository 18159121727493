import {FC} from 'react';
import styles from './index.module.scss';
import { Button } from '../../../button';
import { goTo } from '../../../../helpers/goTo';

export const Slide: FC = () => {
	return (
		<div className={styles.slide}>
			<div className={styles.content}>
				<h4 className={styles.title}>
					Маркетплейс, <br/> где всё просто
				</h4>
				<span className={styles.text}>Выгодные и прозрачные условия для развития вашего бизнеса</span>
				<Button
					onClick={goTo.registration}
					text="Стать продавцом"
					type="primary"
					size="large"
				/>
			</div>
			<img src="/assets/images/busel.png" alt='Бусел' className={styles.busel}/>
		</div>
	);
};
