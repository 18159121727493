import { FC, ReactElement } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';

interface IProps {
	text: string;
	type: 'primary' | 'secondary';
	size: 'medium' | 'large';
	className?: string;
	onClick: () => unknown;
	icon?: ReactElement;
}

export const Button: FC<IProps> = ({ text, className, type, size, onClick, icon }) => (
	<button
		onClick={onClick}
		className={classNames(styles.button, className, {
			[styles.button_size_large]: size === 'large',
			[styles.button_size_medium]: size === 'medium',
			[styles.button_type_primary]: type === 'primary',
			[styles.button_type_secondary]: type === 'secondary',
		})}
	>
		{text}
		{icon}
	</button>
);
