import { Breakpoint, useBreakpoint } from './useBreakpoint';
import { useEffect, useState } from 'react';

export const useIsMobile = (...size: (Breakpoint | undefined)[]): boolean => {
	const [isMobile, setIsMobile] = useState(false);
	const breakpoint = useBreakpoint();

	useEffect(() => {
		const result = ['xxs', 'xs', ...size].includes(breakpoint);
		if (result !== isMobile) setIsMobile(result);
	}, [breakpoint]);

	return isMobile;
};
