import { FC } from 'react';

interface IProps {
	className?: string;
}

export const Question: FC<IProps> = ({ className }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
				stroke="#B5B5B8"
				strokeWidth="1.6"
				strokeLinecap="round"
			/>
			<path
				d="M19 10C19 14.9706 14.9706 19 10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10Z"
				stroke="#B5B5B8"
				strokeWidth="1.6"
				strokeLinecap="round"
			/>
			<path
				d="M9.79907 11.8842C9.40216 11.8842 9.07512 11.5592 9.11642 11.1645C9.14697 10.8724 9.19552 10.6218 9.26207 10.4126C9.38139 10.0481 9.55043 9.75308 9.76918 9.5277C9.98793 9.30232 10.2514 9.09683 10.5597 8.91122C10.7585 8.78527 10.9375 8.64441 11.0966 8.48864C11.2557 8.33286 11.3816 8.15388 11.4744 7.9517C11.5672 7.74953 11.6136 7.5258 11.6136 7.28054C11.6136 6.98556 11.544 6.73035 11.4048 6.51491C11.2656 6.29948 11.08 6.13376 10.848 6.01776C10.6193 5.89844 10.3641 5.83878 10.0824 5.83878C9.82718 5.83878 9.58357 5.89181 9.35156 5.99787C9.11955 6.10393 8.92732 6.26965 8.77486 6.49503C8.73887 6.54744 8.70649 6.60346 8.6777 6.66307C8.50252 7.02589 8.19817 7.35511 7.79527 7.35511C7.36114 7.35511 7.00044 6.98832 7.11864 6.5706C7.19448 6.30256 7.30574 6.06027 7.45241 5.84375C7.73082 5.42945 8.09872 5.11624 8.55611 4.90412C9.01681 4.692 9.52557 4.58594 10.0824 4.58594C10.6922 4.58594 11.2259 4.70028 11.6832 4.92898C12.1406 5.15436 12.4953 5.47088 12.7472 5.87855C13.0024 6.28291 13.13 6.75521 13.13 7.29545C13.13 7.66667 13.072 8.00142 12.956 8.29972C12.84 8.5947 12.6742 8.85819 12.4588 9.0902C12.2467 9.32221 11.9915 9.5277 11.6932 9.70668C11.4115 9.88234 11.1828 10.0646 11.0071 10.2536C10.8348 10.4425 10.7088 10.6662 10.6293 10.9247C10.6042 11.0061 10.5828 11.0936 10.565 11.1871C10.4922 11.5693 10.1881 11.8842 9.79907 11.8842ZM9.82884 15.0014C9.55705 15.0014 9.32339 14.9053 9.12784 14.7131C8.93229 14.5175 8.83452 14.2822 8.83452 14.0071C8.83452 13.7353 8.93229 13.5033 9.12784 13.3111C9.32339 13.1155 9.55705 13.0178 9.82884 13.0178C10.0973 13.0178 10.3293 13.1155 10.5249 13.3111C10.7237 13.5033 10.8232 13.7353 10.8232 14.0071C10.8232 14.1894 10.7768 14.3568 10.6839 14.5092C10.5945 14.6584 10.4751 14.7777 10.326 14.8672C10.1768 14.9567 10.0111 15.0014 9.82884 15.0014Z"
				fill="#B5B5B8"
			/>
		</svg>
	);
};
