import { NamePath } from "antd/lib/form/interface";
import clsx from "clsx";
import { FC } from "react";
import { InputOutlined } from "ui-kit-euroopt";

import cls from "./index.module.scss";

interface IInputForm {
  label: string;
  disabled?: boolean;
  value?: string;
  name: NamePath;
  mix?: string;
  type?: string;
  autoComplete?: string;
  onChange?: (x: string | number | undefined) => void;
  getFieldError?: (name: NamePath) => string[];
}

export const InputForm: FC<IInputForm> = ({
  mix,
  name,
  type,
  value,
  label,
  disabled,
  autoComplete,
  onChange,
  getFieldError,
}) => {
  const message = getFieldError
    ? typeof getFieldError(name) === "string"
      ? getFieldError(name)
      : getFieldError(name)[0]
    : "";

  const isError = getFieldError
    ? typeof getFieldError(name) === "string"
      ? true
      : !!getFieldError(name).length
    : false;

  const onChangeHandler = (val: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      onChange(
        type === "number"
          ? val.target.value.replace(/[^0-9]/g, "")
          : val.target.value
      );
    }
  };

  return (
    <InputOutlined
      value={value}
      autoComplete={autoComplete}
      name={autoComplete ? (name as string) : undefined}
      onChange={onChangeHandler}
      disabled={disabled}
      type={type === "numberForm" ? "number" : "string"}
      step={type === "numberForm" ? "0.001" : undefined}
      label={label}
      isError={isError || !!message}
      mix={clsx(cls.wrapper, mix)}
      onWheel={(e) => e.currentTarget.blur()}
      onKeyDown={(e) => {
        if (["ArrowUp", "ArrowDown"].includes(e.code)) e.preventDefault();
      }}
      nonMargin
    />
  );
};
